import React from "react";
import { getFormmatedDate, getFormmatedDateTime } from "../../../utils/helper";
import "./PreviewPatientSampleInformation.css";

const PreviewPatientSampleInformation = ({
  orderDate,
  collectedDate,
  collectedTime,
  patientSampleType,
  reportedDate,
  sampleID,
  institutionSampleID,
}: any) => {
  return (
    <div className="preview-order-table">
      <div className="preview-patient-sample-information-header">Patient sample information</div>
      <div className="preview-filed-value">
        <div className="preview-filed">Ordered:</div>
        <div className="preview-input-value">{orderDate ? getFormmatedDate(orderDate) : ""}</div>
      </div>
      <div className="preview-under-line"></div>
      <div className="preview-filed-value">
        <div className="preview-filed">Collected:</div>
        <div className="preview-input-value">{collectedDate ? getFormmatedDateTime(collectedDate, collectedTime) : ""}</div>
      </div>
      <div className="preview-under-line"></div>
      <div className="preview-filed-value">
        <div className="preview-filed">Type:</div>
        <div className="preview-input-value">{patientSampleType}</div>
      </div>
      <div className="preview-under-line"></div>
      <div className="preview-filed-value">
        <div className="preview-filed">Reported:</div>
        <div className="preview-input-value">{reportedDate ? getFormmatedDate(reportedDate) : ""}</div>
      </div>
      <div className="preview-under-line"></div>
      <div className="preview-filed-value">
        <div className="preview-filed">Sample ID:</div>
        <div className="preview-input-value">{sampleID}</div>
      </div>
      <div className="preview-under-line"></div>
      <div className="preview-filed-value">
        <div className="preview-filed">Institution sample ID:</div>
        <div className="preview-input-value">{institutionSampleID}</div>
      </div>
    </div>
  );
};

export default PreviewPatientSampleInformation;
