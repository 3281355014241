import jsPDF from "jspdf";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReportPdfView from "./report_pdf_view/ReportPdfView";
import { CircularProgress } from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import FormData from "form-data";

const ReportConvertToPdf: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [uploading, setUploading] = useState(false);

  let uploadToDropboxBtnRef = useRef<HTMLButtonElement>(null);

  const {
    laboratoryDirector,

    //Patient Table
    patientName,
    patientDOB,
    patientSex,
    patientID,
    orderBy,
    reportRecipient,
    contactInfo,
    amendedNotes,

    //Patient Sample Information
    orderDate,
    collectedDate,
    collectedTime,
    patientSampleType,
    reportedDate,
    sampleID,
    institutionSampleID,

    //datepoints
    datePointsTable,
  } = location.state;


  async function generatePdf(generateOnly = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      var pdf = new jsPDF("p", "pt", "a4", true);

      const Gill = require("./../fonts/Gill Sans.ttf");
      const GiilBold = require("./../fonts/Gill Sans Bold.ttf");
      const GiilMedium = require("./../fonts/Gill Sans Medium.ttf");
      const Arial = require("./../fonts/ARIAL.TTF");

      pdf.addFont(GiilBold, "Gill Sans", "bold");
      pdf.addFont(GiilMedium, "Gill Sans", "normal");
      pdf.addFont(Arial, "Arial Custom", "normal");

      document.body.style.marginTop = "0px";
      document.body.style.marginBottom = "0px";

      // valid code start
      var documentBody = document.getElementById("report_pdf_view_container");
      if (documentBody) {
        documentBody.setAttribute("class", "report-preveiw pdf-download");

        pdf.html(documentBody, {
          callback: function (pdf: any) {
            console.log("pdf: ", pdf);

            document.body.style.width = "initial";
            if (documentBody) {
              documentBody.setAttribute("class", "report-preveiw");
            }
            if (generateOnly === false) {
              pdf.save("final_report.pdf");

              resolve(null);
            }
            else {
              let pdfContent = pdf.output('arraybuffer');
              const saveContent = (content: any) => {
                const blob = new Blob([content], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = 'final_report.pdf';
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
              };
              // saveContent(pdfContent);
              resolve(pdfContent);

            }

          },
        });
      }
      else {
        resolve(null);
      }
      // valid code end
    });
  }

  async function uploadToBox() {
    setUploading(true);

    let pdfData = await generatePdf(true);

    console.log("printing pdfData: ");
    console.log(pdfData);

    if (!pdfData) {
      setUploading(false);
      return;
    }

    try {
      const pdfFile = new Blob([pdfData], { type: 'application/pdf' });
      const formData = new FormData();
      formData.append('pdfFile', pdfFile);
      formData.append('isBase64Encoded', false);

      const option: AxiosRequestConfig = {
        url: `${process.env.REACT_APP_NODE_ENV==='prod' ? process.env.REACT_APP_API_BASE_URL_PROD : process.env.REACT_APP_API_BASE_URL_DEV}/uploadToBox`,
        data: formData,
        method: "POST",

        headers: {
          "Content-Type": "multipart/form-data",
        },
        maxBodyLength: Infinity,
      };
      const response = await axios(option);

      console.log("uploadToBox->>To Path: ", response.data);
    } catch (error) {
      console.log("uploadToBox->>error: ", error);
      setUploading(false);
    } finally {
      setUploading(false);
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReportPdfView
            laboratoryDirector={laboratoryDirector}
            //Patient Table
            patientName={patientName}
            patientDOB={patientDOB}
            patientSex={patientSex}
            patientID={patientID}
            orderBy={orderBy}
            reportRecipient={reportRecipient}
            contactInfo={contactInfo}
            amendedNotes={amendedNotes}
            //Patient Sample Information
            orderDate={orderDate}
            collectedDate={collectedDate}
            collectedTime={collectedTime}
            patientSampleType={patientSampleType}
            reportedDate={reportedDate}
            sampleID={sampleID}
            institutionSampleID={institutionSampleID}
            //datepoints
            datePointsTable={datePointsTable}
            masterCssClassName={"report-preveiw"}
            previewReportRecipientInfoFieldCssClass={"preview-report-recipient-field"}
            previewContactInfoFieldCssClas={"preview-contact-info-field"}
            topCssClass={"top"}
            previewChartContainerCssClass={"preview-chart-container"}
            pdfViewFinalReportSectionCssClass={"pdfview-final-report-section"}
          />
        </div>
        <div style={{ position: "absolute", top: "30px", right: "50px", display: "flex", gap: "20px" }}>
          <div
            onClick={() => {
              generatePdf();
            }}
            id="d"
            style={{
              height: "40px",
              width: "120px",
              borderRadius: "10px",
              backgroundColor: "#633477",
              color: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "700",
            }}
          >
            DownLoad
          </div>
          <button
            disabled={uploading}
            ref={uploadToDropboxBtnRef}
            onClick={async () => {
              await uploadToBox();
            }}
            id="btn_upload_to_dropbox"
            style={{
              height: "40px",
              width: "120px",
              borderRadius: "10px",
              backgroundColor: "#633477",
              color: "white",
              // display: "flex",
              // justifyContent: "center",
              // flexDirection: "column",
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "700",
            }}
          >
            {uploading ? <CircularProgress /> : 'Upload'}
          </button>
          <div
            onClick={() => {
              navigate(-1);
            }}
            id="d"
            style={{
              height: "40px",
              width: "120px",
              borderRadius: "10px",
              backgroundColor: "#013a5d",
              color: "white",
              display: "flex",
              fontWeight: "700",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Back
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportConvertToPdf;
