import React from "react";
import DiviTum from "../../../assets/div-tum.png";
import "./DiviTumLogo.css";

const DiviTumLogo: React.FC = () => {
  let divtum = "";
  return (
    <div className="preview-divtum-tka-logo">
      <div className="preview-divitum-tka">
        <div className="preview-divitum">
          <img src={DiviTum} alt="DidiTumTka Logo"></img>
        </div>
      </div>
    </div>
  );
};

export default DiviTumLogo;
