import React, { useEffect, useState } from "react";
import TestResults from "../../assets/test-restults.png";
import DiviTumLogo from "../common/divitum_logo/DiviTumLogo";
import "./ReportPdfView.css";
import PreviewPatientSampleInformation from "./patient_sample_information/PreviewPatientSampleInformation";
import PreviewChart from "./preview_chart/PreviewChart";
import PreviewConstantSection from "./preview_constant_section/PreviewConstantSection";
import PreviewPatientTable from "./preview_patient_table/PreviewPatientTable";
import PreviewTestResultHistory from "./preview_test_history/PreveiwTestHistory";
import CommaReplacer from "../common/CommaReplacer";
import PreviewChartAPI from "./preview_chart/PreviewChartAPI";

const A4WidthInMillimeters = 210;
const A4HeightInMillimeters = 297;
const DefaultPPI = 96; // Fallback PPI value

const ReportPdfView = ({
  laboratoryDirector,

  //Patient Table
  patientName,
  patientDOB,
  patientSex,
  patientID,
  orderBy,
  reportRecipient,
  contactInfo,
  amendedNotes,

  //Patient Sample Information
  orderDate,
  collectedDate,
  collectedTime,
  patientSampleType,
  reportedDate,
  sampleID,
  institutionSampleID,

  //datepoints
  datePointsTable,
  masterCssClassName,
  previewReportRecipientInfoFieldCssClass,
  previewContactInfoFieldCssClas,
  topCssClass,
  previewChartContainerCssClass,
  pdfViewFinalReportSectionCssClass
}: any) => {

  const [ppi, setPpi] = useState<number>(0.0);
  const [a4WidthInPixels, setA4WidthInPixels] = useState<number>(0.0);
  const [a4HeightInPixels, setA4HeightInPixels] = useState<number>(0.0);

  useEffect(() => {
    const screenWidthInPixels = window.screen.width;
    const screenWidthInInches = screenWidthInPixels / DefaultPPI;
    const calculatedPpi = screenWidthInPixels / screenWidthInInches;



    setPpi(calculatedPpi);
    console.log("calculated PPI: ", calculatedPpi);
    const a4WidthInPixelsLocal = (A4WidthInMillimeters / 25.4) * calculatedPpi;
    const a4HeightInPixelsLocal = (A4HeightInMillimeters / 25.4) * calculatedPpi;
    console.log("a4WidthInPixelsLocal: ", a4WidthInPixelsLocal);
    console.log("a4HeightInPixelsLocal: ", a4HeightInPixelsLocal);
    setA4WidthInPixels(a4WidthInPixelsLocal);
    setA4HeightInPixels(a4HeightInPixelsLocal);

    setDataToLocalStorage();
  }, []);

  const setDataToLocalStorage = () => {
    const jsonData = {
      laboratoryDirector,

      //Patient Table
      patientName,
      patientDOB,
      patientSex,
      patientID,
      orderBy,
      reportRecipient,
      contactInfo,
      amendedNotes,

      //Patient Sample Information
      orderDate,
      collectedDate,
      collectedTime,
      patientSampleType,
      reportedDate,
      sampleID,
      institutionSampleID,

      //datepoints
      datePointsTable,
    };
    console.log("will set jsonData: ", jsonData);
    localStorage.setItem("report", JSON.stringify(jsonData));
  };

  return (
    <div id="report_pdf_view_container" className={masterCssClassName}>
      <div className={topCssClass}>
        {topCssClass === "top" && <>
          <DiviTumLogo />
          <div className="preview-test-report">
            <img alt="Test Results" src={TestResults} width={130}></img>
          </div>
        </>
        }
        {topCssClass === "top-api" && <div className="top-one">
          <DiviTumLogo />
          <div className="preview-test-report">
            <img alt="Test Results" src={TestResults} width={190}></img>
          </div>
        </div>
        }
        {/* <div>&nbsp;</div> */}
        <div className={pdfViewFinalReportSectionCssClass}>
          <div className="pdfview-final-report">
            <span>FINAL REPORT</span>
          </div>
          <div className="pdfview-labratory-director">
            <div>Laboratory director <span>:</span></div>
            <div>Page: 1 of 1</div>
          </div>
          <div className="pdfview-labratory-director-name">
            <CommaReplacer
              value={laboratoryDirector}
              ml="-2.5px"
            />
          </div>
        </div>
      </div>
      <div className="pdfview-patient-table">
        <PreviewPatientTable
          patientName={patientName}
          patientDOB={patientDOB}
          patientSex={patientSex}
          patientID={patientID}
          orderBy={orderBy}
          reportRecipient={reportRecipient}
          contactInfo={contactInfo}
          amendedNotes={amendedNotes}
          previewReportRecipientInfoFieldCssClass={previewReportRecipientInfoFieldCssClass}
          previewContactInfoFieldCssClas={previewContactInfoFieldCssClas}
        />
      </div>
      <div className="pdfview-psi-history-test-result">
        <PreviewPatientSampleInformation
          orderDate={orderDate}
          collectedDate={collectedDate}
          collectedTime={collectedTime}
          patientSampleType={patientSampleType}
          reportedDate={reportedDate}
          sampleID={sampleID}
          institutionSampleID={institutionSampleID}
        />
        <PreviewTestResultHistory datePointsTable={datePointsTable} />
      </div>
      <div className="pdfview-tka-section">
        <div className="pdfview-tka-bar">
          <div className="pdfview-tka-full">Thymidine Kinase activity</div>
          <div className="pdfview-tka">(TKa)</div>
        </div>
      </div>
      {/* {masterCssClassName === 'report-preveiw-api' ? (
        <PreviewChartAPI datePointsTable={datePointsTable} previewChartContainerCssClass={previewChartContainerCssClass} />
      ): (
        <PreviewChart datePointsTable={datePointsTable} previewChartContainerCssClass={previewChartContainerCssClass} />
      )} */}
      
      <PreviewChart
        datePointsTable={datePointsTable}
        masterCssClassName={masterCssClassName}
        previewChartContainerCssClass={previewChartContainerCssClass}
      />

      <PreviewConstantSection />
    </div>
  );
};

export default ReportPdfView;
