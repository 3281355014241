import React, { useEffect, useState } from "react";
import { getFormmatedDate } from "../../../utils/helper";
import "./PreviewTestHistory.css";

const PreviewTestResultHistory = ({ datePointsTable }: any) => {
  const [testResultDates, setTestResultDates] = useState<string[]>([]);
  const [testResultValues, setTestResultValues] = useState<number[]>([]);
  const [reversedDatePointsTable, setReversedDatePointsTable] = useState<any[]>([]);
  const referenceDua = "Reference range: < 250 DuA";

  useEffect(() => {
    setReversedDatePointsTable(datePointsTable.slice().reverse());
  }, [datePointsTable]);

  useEffect(() => {
    const _dates: string[] = [];
    const _values: number[] = [];
    reversedDatePointsTable.forEach((_item) => {
      _dates.push(getFormmatedDate(_item.date));
      _values.push(_item.point);
    });
    setTestResultDates(_dates);
    setTestResultValues(_values);
  }, [reversedDatePointsTable]);

  const getFormattedTestValue = (_value: number) => {
    return <span>
      {_value ?
        (_value > 2000
          ? (<><span className="less-than">&gt;</span>2000</>)
          : _value < 100
            ? (<><span className="less-than">&lt;</span>100</>)
            : _value
        )
        : ""
      }
    </span>
  }

  return (
    <div className="preview-test-result-history">
      <div className="preview-test-result-history-header">
        <span>History of test results</span>
      </div>
      <div className="preview-divtum-unit">
        <div>DiviTum</div>
        <span className="r-circle" style={{ fontSize: "8px", marginTop: "-2px" }}>
          ®
        </span>
        <div style={{ marginLeft: "2px" }}>Units</div>
        <div style={{ marginLeft: "2px" }}>of</div>
        <div style={{ marginLeft: "2px" }}>Activity (DuA)</div>
      </div>
      <div className="reference-dua">{referenceDua}</div>
      <div className="preview-test-result-history-data">
        <div className="preview-items-column">
          <div className="preview-each-test">
            <div className="preview-most-recent-value">
              <div className="preview-test-date">{testResultDates[0] || ''}</div>
              <div className={`preview-test-value ${testResultValues[0] < 100 ? 'less-than-100' : ''}`}>
                {getFormattedTestValue(testResultValues[0])}
              </div>
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[1] || ''}</div>
            <div className={`preview-test-value ${testResultValues[1] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[1])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[2] || ''}</div>
            <div className={`preview-test-value ${testResultValues[2] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[2])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[3] || ''}</div>
            <div className={`preview-test-value ${testResultValues[3] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[3])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[4] || ''}</div>
            <div className={`preview-test-value ${testResultValues[4] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[4])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[5] || ''}</div>
            <div className={`preview-test-value ${testResultValues[5] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[5])}
            </div>
          </div>
        </div>
        <div className="preview-items-column">
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[6] || ''}</div>
            <div className={`preview-test-value ${testResultValues[6] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[6])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[7] || ''}</div>
            <div className={`preview-test-value ${testResultValues[7] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[7])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[8] || ''}</div>
            <div className={`preview-test-value ${testResultValues[8] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[8])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[9] || ''}</div>
            <div className={`preview-test-value ${testResultValues[9] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[9])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[10] || ''}</div>
            <div className={`preview-test-value ${testResultValues[10] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[10])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[11] || ''}</div>
            <div className={`preview-test-value ${testResultValues[11] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[11])}
            </div>
          </div>
        </div>
        <div className="preview-items-column">
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[12] || ''}</div>
            <div className={`preview-test-value ${testResultValues[12] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[12])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[13] || ''}</div>
            <div className={`preview-test-value ${testResultValues[13] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[13])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[14] || ''}</div>
            <div className={`preview-test-value ${testResultValues[14] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[14])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[15] || ''}</div>
            <div className={`preview-test-value ${testResultValues[15] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[15])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[16] || ''}</div>
            <div className={`preview-test-value ${testResultValues[16] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[16])}
            </div>
          </div>
          <div className="preview-each-test">
            <div className="preview-test-date">{testResultDates[17] || ''}</div>
            <div className={`preview-test-value ${testResultValues[17] < 100 ? 'less-than-100' : ''}`}>
              {getFormattedTestValue(testResultValues[17])}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewTestResultHistory;
