import React, { useState } from "react";
import DatePicker from "react-datepicker";
import VerticalDotLine from "../common/VerticalDotLine";
import "./PatientTable.css";

const PatientTable = ({
  patientName,
  setPatientName,
  patientDOB,
  setPatientDOB,
  patientSex,
  setPatientSex,
  patientID,
  setPatientID,
  orderBy,
  setOrderBy,
  reportRecipient,
  setReportRecipient,
  amendedNotes,
  setAmendedNotes,
  contactInfo,
  setContactInfo,
}: any) => {
  return (
    <div className="patient-order-by">
      <div className="patient">
        <div className="patient-name-field">
          <div className="patient-name">Patient name: </div>
          <input
            type="text"
            className="patient-name-value"
            placeholder="Write patient name"
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
          />
        </div>
        <div className="table-header-input-data">
          <div className="table-header">
            <div className="date-of-birth">Date of Birth</div>
            <div className="sex">Sex</div>
            <div className="patient-id">Patient ID</div>
          </div>
          <div className="table-input">
            <div className="date-of-birth-field">
              <DatePicker
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/dd/yyyy"
                selected={patientDOB}
                onChange={(date) => setPatientDOB(date)}
              />
            </div>
            <VerticalDotLine height={10} />
            <select
              value={patientSex}
              defaultValue="F"
              onChange={(e) => setPatientSex(e.target.value)}
              className="sex-field"
            >
              <option value="F">F</option>
              <option value="M">M</option>
              <option value="O">O</option>
            </select>
            <VerticalDotLine height={10} />
            <input
              type="text"
              className="patient-id-field"
              placeholder="Patient ID"
              value={patientID}
              onChange={(e) => setPatientID(e.target.value)}
            />
          </div>
          <div className="table-amended-notes">
            <textarea
              rows={5}
              className="amended-note-input"
              placeholder="Amended Note"
              value={amendedNotes}
              onChange={(e) => setAmendedNotes(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="order-by">
        <div className="orderby-name-field">
          <div className="order-by-name">Order by: </div>
          <input
            type="text"
            className="order-by-name-value"
            placeholder="Write customer name"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
          />
        </div>
        <div className="table-header-input-data">
          <div className="table-header">
            <div className="report-recipient">Report Recipient</div>
            <div className="contact-info">Contact Info</div>
          </div>
          <div className="table-input">
            <textarea
              rows={5}
              className="report-recipient-field"
              placeholder="Report Recipient"
              value={reportRecipient}
              onChange={(e) => setReportRecipient(e.target.value)}
            ></textarea>
            <VerticalDotLine height={20} />
            <textarea
              rows={5}
              className="contact-info-field"
              placeholder="Contact Info"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientTable;
