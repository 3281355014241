import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "./../utils/notifications";

import CustomChart from "./custom-chart/CustomChart";

import "./Dashboard.css";
import PatientTable from "./patient/PatientTable";
import PatientSampleInformation from "./patient_sample_information/PatientSampleInformation";
import TestResultHistory from "./test_result_history/TestResultHistory";
// import TkaMultiSelectDropdown from "./tka_dropdown/TkaDropdown";

import ConstantSection from "../components/constant_section/ConstantSection";
import ReportPdfView from "./report_pdf_view/ReportPdfView";
import { isValidTimeFormat } from "../utils/helper";

const Dashboard: React.FC = (props: any) => {
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isPreviewReport, setisPreviewReport] = useState(false);

  const [laboratoryDirector, setLabratoryDirector] = useState("");

  const [patientName, setPatientName] = useState("");
  const [patientDOB, setPatientDOB] = useState<any>(null);
  const [patientSex, setPatientSex] = useState("F");
  const [patientID, setPatientID] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [reportRecipient, setReportRecipient] = useState("");
  const [amendedNotes, setAmendedNotes] = useState("");
  const [contactInfo, setContactInfo] = useState("");

  const [orderDate, setOrderDate] = useState<any>(null);
  const [collectedDate, setCollectedDate] = useState<any>(null);
  const [collectedTime, setCollectedTime] = useState<any>('');
  const [patientSampleType, setPatientSampleType] = useState("Serum");
  const [reportedDate, setReportedDate] = useState<any>(null);
  const [sampleID, setSampleID] = useState("");
  const [institutionSampleID, setInstitutionSampleID] = useState("");

  const [datePointsTable, setDatePointsTable] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getCurrentAuthUser();

    setDataFromLocalStorage();

    window.addEventListener("beforeunload", (ev) => {
      localStorage.removeItem("report");
    });

    return () => {
      window.removeEventListener("beforeunload", (ev) => {
        console.log("listener is removed");
      });
    };
  }, []);

  const getCurrentAuthUser = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();

      if (user) {
        setCurrentUser(user);
      } else {
        history("/signin", { replace: true });
      }
      setIsLoading(false);
    } catch (error: any) {
      console.log("error dashboard: ", error);
      Toast("Error!!", error.message, "danger");
      setIsLoading(false);

      // history("/signin");
    }
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      Toast("Success!!", "Logged out successfully!", "success");
      history("/signin", { replace: true });
    } catch (error: any) {
      Toast("Error!!", error.message, "danger");
    }
  };

  const setDataToLocalStorage = () => {
    const jsonData = {
      laboratoryDirector,

      //Patient Table
      patientName,
      patientDOB,
      patientSex,
      patientID,
      orderBy,
      reportRecipient,
      contactInfo,
      amendedNotes,

      //Patient Sample Information
      orderDate,
      collectedDate,
      collectedTime,
      patientSampleType,
      reportedDate,
      sampleID,
      institutionSampleID,

      //datepoints
      datePointsTable,
    };
    console.log("will set jsonData: ", jsonData);
    localStorage.setItem("report", JSON.stringify(jsonData));
  };

  const setDataFromLocalStorage = () => {
    const jsonData = JSON.parse(localStorage.getItem("report") || "{}");

    setLabratoryDirector(laboratoryDirector || "");

    setPatientName(jsonData.patientName || "");
    setPatientDOB(jsonData.patientDOB ? new Date(jsonData.patientDOB) : null);
    setPatientSex(jsonData.patientSex || "F");
    setPatientID(jsonData.patientID || "");
    setOrderBy(jsonData.orderBy || "");
    setReportRecipient(jsonData.reportRecipient || "");
    setContactInfo(jsonData.contactInfo || "");
    setAmendedNotes(jsonData.amendedNotes || "");

    setOrderDate(jsonData.orderDate ? new Date(jsonData.orderDate) : null);
    setCollectedDate(jsonData.collectedDate ? new Date(jsonData.collectedDate) : null);
    setCollectedTime(jsonData.collectedTime || '');
    setPatientSampleType(jsonData.patientSampleType || "Serum");
    setReportedDate(jsonData.reportedDate ? new Date(jsonData.reportedDate) : null);
    setSampleID(jsonData.sampleID || "");
    setInstitutionSampleID(jsonData.institutionSampleID || "");

    setDatePointsTable(
      jsonData.datePointsTable?.map((_item: any) => {
        return {
          ..._item,
          date: new Date(_item.date),
        };
      }) || []
    );

    console.log("localstorage: ", jsonData);
  };

  if (isLoading) {
    return null;
  }
  return (
    <>
      <div className="wrap">
        <div className="main-wrap">
          <div className="logout-button-user-wrap">
            <div className="logout-button-user">
              <div className="user-name">Logged in as {currentUser.attributes.name}</div>
              <div className="logout-button" onClick={handleLogout}>
                <div className="logout"> Logout</div>
                <div className="logout-icon">
                  <ExitToAppIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="header-section">
            <div className="header-log">
              <div className="divitum-tka">
                <div className="divitum">DiviTum</div>
                <div className="dashboard-r-circle ">®</div>
                <div className="tka-text">TKa</div>
                <div className="headline">TEST RESULTS</div>
              </div>
            </div>
            <div className="final-report-button">
              <div className="report-button">FINAL REPORT</div>
              <div className="director-name">Laboratory director :</div>
              <input
                type="text"
                className="director-name-value"
                onChange={(event) => {
                  setLabratoryDirector(event.target.value);
                  console.log("labratory director", laboratoryDirector);
                }}
              ></input>
            </div>
          </div>
          <PatientTable
            patientName={patientName}
            setPatientName={setPatientName}
            patientDOB={patientDOB}
            setPatientDOB={setPatientDOB}
            patientSex={patientSex}
            setPatientSex={setPatientSex}
            patientID={patientID}
            setPatientID={setPatientID}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            reportRecipient={reportRecipient}
            setReportRecipient={setReportRecipient}
            amendedNotes={amendedNotes}
            setAmendedNotes={setAmendedNotes}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
          />
          <div className="psi-history-test-result">
            <PatientSampleInformation
              orderDate={orderDate}
              setOrderDate={setOrderDate}
              collectedDate={collectedDate}
              collectedTime={collectedTime}
              setCollectedDate={setCollectedDate}
              setCollectedTime={setCollectedTime}
              patientSampleType={patientSampleType}
              setPatientSampleType={setPatientSampleType}
              reportedDate={reportedDate}
              setReportedDate={setReportedDate}
              sampleID={sampleID}
              setSampleID={setSampleID}
              institutionSampleID={institutionSampleID}
              setInstitutionSampleID={setInstitutionSampleID}
            />
            <TestResultHistory datePointsTable={datePointsTable} />
          </div>
          <div className="tka-section">
            <div className="tka-bar">
              <div className="tka-full">Thymidine Kinase activity</div>
              <div className="tka">(TKa)</div>
            </div>
          </div>
          <CustomChart
            datePointsTable={datePointsTable}
            setDatePointsTable={setDatePointsTable}
            collectedDate={collectedDate}
            collectedTime={collectedTime}
          />
          <ConstantSection />
          <div className="preview-convert">
            <div
              className="preview-button"
              onClick={() => {
                window.scrollTo(0, 0)
                setDataToLocalStorage();
                setisPreviewReport(!isPreviewReport);
              }}
            >
              Preview Report
            </div>
            <div
              className="preview-button"
              onClick={() => {
                if (!collectedDate) {

                  Toast("Warning!!", 'The collected date must be selected!!', "warning");
                  return
                }

                if (collectedTime && collectedTime.length > 0 && !isValidTimeFormat(collectedTime)) {
                  Toast("Warning!!", 'The collected time (HH:mm aa) must be valid!!', "warning");
                  return
                }

                window.scrollTo(0, 0)
                setDataToLocalStorage();
                history("/convert-to-pdf", {
                  state: {
                    laboratoryDirector,

                    //Patient Table
                    patientName,
                    patientDOB,
                    patientSex,
                    patientID,
                    orderBy,
                    reportRecipient,
                    contactInfo,
                    amendedNotes,

                    //Patient Sample Information
                    orderDate,
                    collectedDate,
                    collectedTime,
                    patientSampleType,
                    reportedDate,
                    sampleID,
                    institutionSampleID,

                    //datepoints
                    datePointsTable,
                  },
                });
                // window.print();
              }}
            >
              Convert to PDF
            </div>
          </div>
        </div>
        {isPreviewReport && (
          <div
            className="blur-background"
            onClick={() => {
              setisPreviewReport(false);
            }}
          ></div>
        )}
        {isPreviewReport && (
          <ReportPdfView
            laboratoryDirector={laboratoryDirector}
            //Patient Table
            patientName={patientName}
            patientDOB={patientDOB}
            patientSex={patientSex}
            patientID={patientID}
            orderBy={orderBy}
            reportRecipient={reportRecipient}
            contactInfo={contactInfo}
            amendedNotes={amendedNotes}
            //Patient Sample Information
            orderDate={orderDate}
            collectedDate={collectedDate}
            collectedTime={collectedTime}
            patientSampleType={patientSampleType}
            reportedDate={reportedDate}
            sampleID={sampleID}
            institutionSampleID={institutionSampleID}
            //datepoints
            datePointsTable={datePointsTable}

            masterCssClassName={"report-preveiw"}
            previewReportRecipientInfoFieldCssClass={"preview-report-recipient-field"}
            previewContactInfoFieldCssClas={"preview-contact-info-field"}
            topCssClass={"top"}
            previewChartContainerCssClass={"preview-chart-container"}
            pdfViewFinalReportSectionCssClass={"pdfview-final-report-section"}
          />
        )}
      </div>
    </>
  );
};

export default Dashboard;
