import React from 'react'

const CommaReplacer = ({
  value,
  ml
}: {
  value: string,
  ml: string
}) => {
  const splited = value ? value.split(',').filter((_it: string) => _it && _it !== '') : []

  return (
    <>
      {splited.map((_it, _in) => {
        if (_in < splited.length - 1) {
          return (
            <>
              <span>{_it}</span>
              <span
                style={{
                  marginLeft: ml,
                }}
              >
                ,
              </span>
            </>
          )
        }
        return <span>{_it}</span>
      })}
    </>
  );
}

export default CommaReplacer