import React, { useEffect, useState } from "react";
import ReportPdfView from "./report_pdf_view/ReportPdfView";
import axios, { AxiosRequestConfig } from "axios";
import { useSearchParams } from 'react-router-dom';
import jsPDF from "jspdf";
const ReportConvertToPdfFromApi: React.FC = () => {
  const [laboratoryDirector, setLaboratoryDirectory] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientDOB, setPatientDOB] = useState("");
  const [patientSex, setPatientSex] = useState("");
  const [patientID, setPatientID] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [reportRecipient, setReportRecipient] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [amendedNotes, setAmendedNotes] = useState("");

  const [orderDate, setOrderDate] = useState("");
  const [collectedDate, setCollectedDate] = useState("");
  const [collectedTime, setCollectedTime] = useState("");
  const [patientSampleType, setPatientSampleType] = useState("");
  const [reportedDate, setReportedDate] = useState("");
  const [sampleID, setSampleID] = useState("");
  const [institutionSampleID, setInstituitionSampleID] = useState("");
  const [datePointsTable, setDatePointsTable] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [allData, setAllData] = useState<any>(null);
  const [qbenchOrderId, setQbenchOrderId] = useState('');

  const loadData = async () => {

    setIsLoading(true);
    try {
      const q = searchParams.get('qbenchOrderId') || "";
      console.log("q: ", q);

      const options: AxiosRequestConfig = {
        method: "POST",
        url: `${process.env.REACT_APP_NODE_ENV === 'prod' ? process.env.REACT_APP_API_BASE_URL_PROD : process.env.REACT_APP_API_BASE_URL_DEV}/generateReportOnAmendedOrCorrectedApi`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          qbenchOrderId: q,
          isBase64Encoded: false,
        },

      };

      let response = await axios(options);
      console.log("response.data: ", response.data);
      if (response.data) {
        setLaboratoryDirectory(response.data.laboratoryDirector);
        setPatientName(response.data.patientName);
        setPatientDOB(response.data.patientDOB);
        setPatientSex(response.data.patientSex);
        setPatientID(response.data.patientID);
        setOrderBy(response.data.orderBy);
        setReportRecipient(response.data.reportRecipient);
        setContactInfo(response.data.contactInfo);
        setAmendedNotes(response.data.amendedNotes);
        setOrderDate(response.data.orderDate);
        setCollectedDate(response.data.collectedDate);
        setCollectedTime(response.data.collectedTime); // HH:mm AM/PM format
        setPatientSampleType(response.data.patientSampleType);
        setReportedDate(response.data.reportedDate);
        setSampleID(response.data.sampleID);
        setInstituitionSampleID(response.data.institutionSampleID);
        setDatePointsTable(response.data.datePointsTable);


        setAllData(response.data);

      }
      else {
        setAllData({});
      }

      setIsLoading(false);
      setQbenchOrderId(q);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (isLoading) {
      loadData();
    }
  }, [isLoading]);


  if (!isLoading) {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReportPdfView
              laboratoryDirector={laboratoryDirector}
              //Patient Table
              patientName={patientName}
              patientDOB={patientDOB}
              patientSex={patientSex}
              patientID={patientID}
              orderBy={orderBy}
              reportRecipient={reportRecipient}
              contactInfo={contactInfo}
              amendedNotes={amendedNotes}
              //Patient Sample Information
              orderDate={orderDate}
              collectedDate={collectedDate}
              collectedTime={collectedTime}
              patientSampleType={patientSampleType}
              reportedDate={reportedDate}
              sampleID={sampleID}
              institutionSampleID={institutionSampleID}
              //datepoints
              datePointsTable={datePointsTable}
              masterCssClassName={"report-preveiw-api"}
              previewReportRecipientInfoFieldCssClass={"preview-report-recipient-field-api"}
              previewContactInfoFieldCssClas={"preview-contact-info-field-api"}
              topCssClass={"top-api"}
              previewChartContainerCssClass={"preview-chart-container"}
              pdfViewFinalReportSectionCssClass={"pdfview-final-report-section-api"}
              previewConstantSectionHeaderCssClass={"preview-constant-section-header-api"}
            />
          </div>

        </div>
      </>
    );
  }
  else {
    return null;
  }
};

export default ReportConvertToPdfFromApi;
