import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Auth } from "aws-amplify";
import { Card } from '@mui/material';

function Protected(props: any) {

    if (props.loggedIn === true) {
        return props.children

    }
    else if (props.loggedIn === false) {
        return <Navigate to="/signin" replace />
    }
    else {
        return <Card />
    }
}
export default Protected