import React from "react";
import BiovicaLogo from "../../assets/biovica-logo.png";
import "./ConstantSection.css";

const ConstantSection: React.FC = () => {
  const generalInformation = [
    { info: "TKa values are associated with disease progression within 30 days or 60 days from sampling" },
    {
      info: "TKa values < 250 DuA are associated with decreased likelihood of disease progression within the next 30 days or 60 days post testing (81% and 82% specificity, respectively)",
    },
    {
      info: "TKa values < 250 DuA have negative predictive values for disease progression of 97% and 94% for 30 days or 60 days, respectively, post-testing progression-free survival (PFS)",
    },
    {
      info: "Testing for TKa should be used in conjunction with other clinical methods used for monitoring metastatic breast cancer patients",
    },
    {
      info: "DiviTum TKa should not be used for cancer screening or diagnosis"
    },
    {
      info: "DiviTum TKa is not for “serial testing” because the test result at a given time point does not compare to the test result at previous time point, but to a fixed cutoff value",
    },
    {
      info: " DiviTum TKa is not indicated as a stand-alone test to determine the outcome of disease nor to suggest or infer an individual patient’s likely benefit from therapy",
    },
  ];

  const limitations = [
    {
      info: "The DiviTum TKa test has been validated for use only with serum samples from postmenopausal women previously diagnosed with HR+ metastatic breast cancer",
    },
    {
      info: "Accurate results are dependent on following the proper sample collection, storage, and handling procedures",
    },
    {
      info: "Patients who have high levels of triglycerides may show falsely elevated TKa values when tested, and who have high levels of bilirubin (conjugated) may show falsely depressed TKa values when tested with DiviTum TKa",
    },
    {
      info: " Patients who are taking cisplatin may show falsely depressed TKa values when tested with DiviTum TKa",
    },
    {
      info: "The effects of chemotherapy on DiviTum TKa have not yet been fully evaluated",
    },
  ];
  const chartInfo = "* < 145 DuA for patients treated with CDK4/6 inhibitor.";
  return (
    <div className="constant-section">
      <div className="chart-static-note">{chartInfo}<sup>2,3</sup></div>
      <div className="horizontal-dotted-line"></div>
      <div className="general-test-information">
        <div className="warnings-limitations">
          <div className="warnings">
            <div className="common-header">General test information:</div>
            <ul className="list">
              {generalInformation.map((e) => {
                return <li>{e.info}</li>;
              })}
            </ul>
          </div>
          <div className="limitations">
            <div className="common-header">Limitations:</div>
            <ul className="list">
              {limitations.map((e) => {
                return <li>{e.info}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="reference-section">
          <strong className="reference">References: 1.</strong> Paoletti C, Barlow WE, Cobain EF, et al. Evaluating serum
          thymidine kinase 1 in patients with hormone receptor-positive metastatic breast cancer receiving first-line
          endocrine therapy in the SWOG S0226 trial. Clin Cancer Res. 2021;27(22):6115-6123.
          <strong className="reference">2.</strong> Malorni L, De Laurentiis M, Bianchini G, et al. Serum thymidine
          kinase 1 activity in patients with hormone receptor positive (HR+)/HER2 negative (HER2–) advanced breast
          cancer (aBC) treated in first line with ribociclib and letrozole in the BioltaLEE trial. Poster presented at:
          European Society for Medical Oncology (ESMO) Congress (held virtually); September 16-21, 2021.
          <strong className="reference">3.</strong> Malorni L, Tyekucheva S, Hilbers FS, et al; International Breast
          Cancer Study Group; Breast International Group and PYTHIA Collaborators. Serum thymidine kinase activity in
          patients with hormone receptor-positive and HER2-negative metastatic breast cancer treated with palbociclib
          and fulvestrant. <i>Eur J Cancer</i>. 2022;164:39-51. <strong className="reference">4.</strong>US Food and Drug
          Administration. July 2022 510(K) Clearances. US Food and Drug Administration website.
          https://www.accessdata.fda.gov/cdrh_docs/pdf20/K202852.pdf. Published July 29, 2022. Accessed March 1, 2023.
          <strong className="reference">5.</strong> Bergqvist M, Nordmark A, Williams A, et al. Thymidine kinase
          activity levels in serum can identify HR+metastatic breast cancer patients with a low risk of early
          progression (SWOG S0226). <i>Biomarkers</i>. 2023 doi:10.1080/1354750X.2023.2168063.
        </div>
        <div className="divider"></div>
        <div className="footer">
          <div className="footer-left">
            <div className="left-footer-text">
              DiviTum TKa has been cleared by the US FDA. Biovica is certified under CLIA to perform high complexity
              clinical laboratory testing. <br></br>The test may be covered by one or more US pending or issued patents—see www.biovica.com for details.
            </div>
            <div className="left-footer-copyright">
              <div>DiviTum</div>
              <div className="r-circle">®</div>
              <div>is a registered trademark of Biovica International AB.</div>
            </div>
            <div>©2023 Biovica, Inc. All rights reserved. BDT1002 March 2023</div>
          </div>
          <div className="footer-right">
            <div className="biovica-logo">
              <img src={BiovicaLogo} height={15}></img>
            </div>
            <div className="address">
              <div>6195 Cornerstone Court E, Suite 101</div>
              <div> San Diego, CA 92121</div>
            </div>
            <a href="www.biovica.com" className="biovica-web-link">
              www.biovica.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConstantSection;
