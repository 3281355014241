import React, { useState, useEffect } from "react";
import { getFormmatedDate } from "../../../utils/helper";
import VerticalDotLine from "../../common/VerticalDotLine";
import "./PreviewPatientTable.css";
import CommaReplacer from "../../common/CommaReplacer";
import parse from 'html-react-parser';

const PreviewPatientTable = ({
  patientName,
  patientDOB,
  patientSex,
  patientID,
  orderBy,
  reportRecipient,
  contactInfo,
  amendedNotes,
  previewReportRecipientInfoFieldCssClass,
  previewContactInfoFieldCssClas,

}: any) => {
  const [selectedGender, setSelectedGender] = useState("");

  const handleChange = (event: any) => {
    setSelectedGender(event.target.value);
  };

  return (
    <div className="preview-patient-order-by">
      <div className="preview-patient">
        <div className="preview-patient-name-field">
          <div className="preview-patient-name">Patient name: </div>
          <div className="preview-patient-name-value">
            <CommaReplacer
              value={patientName}
              ml="0px"
            />
          </div>
        </div>
        <div className="preview-table-header-input-data">
          <div className="preview-table-header">
            <div className="preview-date-of-birth">Date of birth:</div>
            <div className="preview-sex">Sex:</div>
            <div className="preview-patient-id">Patient ID:</div>
          </div>
          <div className="preview-table-input">
            <div className="preview-date-of-birth-field">{patientDOB ? getFormmatedDate(patientDOB) : "MM/DD/YYYY"}</div>
            <VerticalDotLine height={10} />
            <div className="preview-sex-field">{patientSex}</div>
            <VerticalDotLine height={10} />
            <div className="preview-patient-id-field">{patientID}</div>
          </div>
          <div className="preview-amended-notes">
            <span><b>Notes </b> </span>
            <span style={{ marginLeft: '1.5px' }}><b>: </b> </span>
            <span className="notes-content">{amendedNotes}</span>
          </div>
        </div>
      </div>
      <div className="preview-order-by">
        <div className="preview-orderby-name-field">
          <div className="preview-order-by-name">Order by: </div>
          <div className="preview-order-by-name-value">
            <CommaReplacer
              value={orderBy}
              ml="-2.5px"
            />
          </div>
        </div>
        <div className="preview-table-header-input-data">
          <div className="preview-table-header">
            <div className="preview-report-recipient table-field">Report recipient:</div>
            <div className="preview-contact-info">Contact info:</div>
          </div>
          <div className="preview-table-input">
            <div className={previewReportRecipientInfoFieldCssClass}>{parse(reportRecipient)}</div>
            <VerticalDotLine height={20} />
            <div className={previewContactInfoFieldCssClas}>{parse(contactInfo)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPatientTable;
