import React, { createRef, useEffect, useState } from "react";
import { getFormmatedDate } from "../../../utils/helper";

import "./PreviewChart.css";
import DuaPNG from '../../../assets/DuApng.png'

const PreviewChart = ({
  datePointsTable,
  previewChartContainerCssClass,
  masterCssClassName
}: any) => {
  const [chartDate, setChartDate] = useState("");
  const [chartPoint, setChartPoint] = useState("");
  const [pointRefs, setPointRefs] = useState<any>([]);
  const [pointLines, setPointLines] = useState<any>([]);
  const [datePointsChart, setDatePointsChart] = useState<any[]>([]); //[...datePointsTable]

  const rowHeightRef = createRef<HTMLDivElement>();

  useEffect(() => {
    console.log("datePointsChart.length: ", datePointsChart.length);
    setPointRefs((prevRefs: any) =>
      Array(datePointsChart.length)
        .fill(0)
        .map((_, i) => prevRefs[i] || createRef())
    );
  }, [datePointsChart]);

  useEffect(() => {
    if (datePointsTable.length <= 8) {
      setDatePointsChart(datePointsTable);
    } else {
      setDatePointsChart(datePointsTable.slice(-8));
    }
  }, [datePointsTable]);

  useEffect(() => {
    newDrawer();
  }, [pointRefs]);

  // as from new design, the lines are reomved
  const newDrawer = () => {
    if (pointRefs.length < 2) {
      return;
    }

    const tempLines = [];
    for (let _in = 0; _in < pointRefs.length - 1; ++_in) {
      // if (_in !== 0) break
      let elem1 = pointRefs[_in].current;
      let elem2 = pointRefs[_in + 1].current;
      // const elemContent1 = elem1.querySelector('span')?.textContent || ''
      // const elemContent2 = elem2.querySelector('span')?.textContent || ''
      // console.log("drawer elemContent1: ", elemContent1);

      const rect1 = elem1.getBoundingClientRect();
      const off1 = {
        right: rect1.right + window.pageXOffset,
        top: rect1.top + window.pageYOffset,
        width: rect1.width || elem1.offsetWidth,
        height: rect1.height || elem1.offsetHeight,
      };
      const rect2 = elem2.getBoundingClientRect();
      const off2 = {
        left: rect2.left + window.pageXOffset,
        top: rect2.top + window.pageYOffset,
        width: rect2.width || elem2.offsetWidth,
        height: rect2.height || elem2.offsetHeight,
      };

      // console.log('drawer offset1: ', off1)
      // console.log('drawer offset2: ', off2)
      // console.log('drawer rect1.left: ', rect1.left)
      // console.log('drawer rect1.left: ', rect1.left)
      // console.log('drawer rect2.left: ', rect2.left)

      const thickness = 1;

      const x1 = off1.right - 1; // + off1.width / 2.0
      const y1 = off1.top + off1.height / 2.0;
      // top right
      const x2 = off2.left + 1; // + off2.width / 2.0
      const y2 = off2.top + off2.height / 2.0;

      // distance
      const length = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1) * 1.0) + 2.3;
      // console.log('length: ', length)
      // angle
      const angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI) * 1.0 + 180;

      let cx = 33.5; //
      if (masterCssClassName === 'report-preveiw-api') {
        cx = 49
      }

      let cy = parseFloat(elem1.style.top.slice(0, -2))
      if (masterCssClassName === 'report-preveiw-api') {
        cy += 13.5
      } else {
        cy += 9.5
      }
      // console.log("drawer cx, cy: ", cx, cy);
      
      if ((elem1.innerText.includes("<") || elem1.innerText.includes(">")) && (elem2.innerText.includes("<") || elem2.innerText.includes(">"))) {
        cx += 3;
      } else if ((elem1.innerText.includes("<") || elem1.innerText.includes(">")) && !(elem2.innerText.includes("<") || elem2.innerText.includes(">"))) {
        if (masterCssClassName === 'report-preveiw-api') {
          cx += 1;
        } else { // for frontend report
          if (elem1.innerText.includes("<")) {
            cx += 1
          } else if (elem1.innerText.includes(">")) {
            cx += 3
          }
          
        }
      } else if (!(elem1.innerText.includes("<") || elem1.innerText.includes(">")) && (elem2.innerText.includes("<") || elem2.innerText.includes(">"))) {
        if (masterCssClassName === 'report-preveiw-api') {
          cx -= 2;
        } else {
          cx -= 1;
        }
        
      } 
      

      const htmlLine = (
        <div
          style={{
            position: "absolute",
            top: cy + "px",
            left: cx + "px",
            padding: "0",
            height: thickness + "px",
            width: length + "px",
            transform: "rotate(" + angle + "deg)",
            transformOrigin: "0 0",

            backgroundColor: "rgb(0, 58, 93)",
            lineHeight: "1px",
          }}
        ></div>
      );

      tempLines.push(htmlLine);
      // console.log("\n");
    }

    setPointLines(tempLines);
  };

  const getCalculatedTop = (point: any) => {
    let topPx = -24.5; // -24.5
    let height = 11.0; //16 will change as CSS
    let margin = 24.0; //30 will change as CSS
    if (masterCssClassName === 'report-preveiw-api') {
      topPx = -30.5 // -27.5 is ok for localhost
      height = 16.0
      margin = 30.0
    }

    const largeGap = margin + height;
    const smallGap = height;

    if (point >= 100) {
      topPx -= 8;
    } else {
      topPx -= 3;
    }
    if (point > 100) {
      const result: number = Math.min(largeGap, ((point - 100) / 100.0) * largeGap);
      let high = parseFloat(result.toString()); //.toFixed(2)
      topPx -= high;
    }
    if (point > 200) {
      let high = parseFloat(Math.min(largeGap, ((point - 200) / 100.0) * largeGap).toString()); //.toFixed(2)
      topPx -= high;
    }
    if (point > 300) {
      let high = parseFloat(Math.min(smallGap, ((point - 300) / 100.0) * smallGap).toString()); //.toFixed(2)
      topPx -= high;
    }
    if (point > 400) {
      let high = parseFloat(Math.min(smallGap, ((point - 400) / 100.0) * smallGap).toString()); //.toFixed(2)
      topPx -= high;
    }

    if (point > 500) {
      let high = parseFloat(Math.min(smallGap, ((point - 500) / 100.0) * smallGap).toString()); //.toFixed(2)
      topPx -= high;
    }
    if (point > 600) {
      let high = parseFloat(Math.min(smallGap, ((point - 600) / 100.0) * smallGap).toString()); //.toFixed(2)
      topPx -= high;
    }
    if (point > 700) {
      let high = parseFloat(Math.min(smallGap, ((point - 700) / 100.0) * smallGap).toString()); //.toFixed(2)
      topPx -= high;
    }
    if (point > 800) {
      let high = parseFloat(Math.min(smallGap, ((point - 800) / 100.0) * smallGap).toString()); //.toFixed(2)
      topPx -= high;
    }
    if (point > 900) {
      let high = parseFloat(Math.min(smallGap, ((point - 900) / 1100.0) * smallGap).toString()); //.toFixed(2)
      topPx -= high;
    }

    if (point > 2000) {
      if (masterCssClassName === 'report-preveiw-api') {
        topPx -= 10
      } else {
        topPx -= 5
      }
      
    }

    return topPx + "px";
  };

  return (
    <div className={previewChartContainerCssClass}>
      <div className="main-chart">
        <div className="main-chart-row row-item-2000">
          <div className="row-item-left">
            <span>2000</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-900" ref={rowHeightRef}>
          <div className="row-item-left">
            <span>900</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-800">
          <div className="row-item-left">
            <span>800</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-700">
          <div className="row-item-left">
            <span>700</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-600">
          <div className="row-item-left">
            <span>600</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-500">
          <div className="row-item-left">
            <span>500</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-400">
          <div className="row-item-left">
            <span>400</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-300">
          <div className="row-item-left">
            <span>300</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-200">
          <div className="row-item-left">
            <span>200</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-100">
          <div className="row-item-left">
            <span>100</span>
          </div>
          <div className="row-item-right"></div>
        </div>

        <div className="main-chart-row main-chart-row-custom row-item-250">
          <div className="row-item-left">
            <span>250{/*  */}</span>
            <div className="left-label">
              <span>Reference</span>
              <span>Range</span>
            </div>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row main-chart-row-custom row-item-145">
          <div className="row-item-left">
            <span className="outer-145">
              <span className="span-esteric">*</span><span>145</span>
            </span>
            <div className="left-label">
              {/* <span>CDK4/6</span>
              <span>inhibitor cutoff</span> */}
            </div>
          </div>
          <div className="row-item-right">
            {Array.from({ length: 75 }).map((_it) => (
              <div className="dot"></div>
            ))}
          </div>
        </div>

        <div className="y-legend">
          <img src={DuaPNG} alt="Dua" />
        </div>

        <div className="left-bar"></div>
        <div className="bottom-bar"></div>
      </div>
      <div className="datepoint-container">
        <div className="date-legend">
          <span>
            <strong>DATE:</strong>
          </span>
        </div>
        {datePointsChart.map((_datePoint, _in) => {
          const _floatPoint = parseFloat(_datePoint.point)
          return (
            <div className={`datepoint-item datepoint-item-loop datepoint-item-${_in + 1}`} key={_in}>
              <span>{getFormmatedDate(_datePoint.date)}</span>
              <span className="day-cycle">{_datePoint.dayCycle}</span>
              <div
                className={`point point-${_in}`}
                ref={pointRefs[_in]}
                style={{
                  top: getCalculatedTop(_floatPoint),
                  backgroundColor: _in === datePointsChart.length - 1 ? "#FEFBD1" : "#FFFFFF",
                }}
              >
                <span>{_floatPoint < 100 ? "<100" : _floatPoint > 2000 ? '>2000' : _datePoint.point}</span>
              </div>
              {pointLines[_in] || null}
              {_in === datePointsChart.length - 1 && <div className={`last-box last-box-${_in}`}></div>}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default PreviewChart;
