export const getFormmatedDate = (dateString: string) => {
  const _date = new Date(dateString);
  const yyyy = _date.getFullYear();
  let mm: any = _date.getMonth() + 1; // Months start at 0!
  let dd: any = _date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = mm + "/" + dd + "/" + yyyy;

  return formattedToday;
};

export const getFormmatedDateTime = (dateString: string, timeString: string) => {
  console.log('dateString: ', dateString)
  console.log('timeString: ', timeString)
  const _date = new Date(dateString);
  const yyyy = _date.getFullYear();
  let mm: any = _date.getMonth() + 1; // Months start at 0!
  let dd: any = _date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  let output = mm + "/" + dd + "/" + yyyy;
  
  const _dateTime = new Date(timeString)
  
  if (_dateTime instanceof Date && !isNaN(_dateTime.getTime())) {
    console.log('timeString valid date: ', timeString)
    let hours: any = _dateTime.getHours();
    let minutes: any = _dateTime.getMinutes();
    let ampm: any = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    output += ' ' + strTime
  } else if (timeString) {
    console.log('timeString invalid date: ', timeString)
    output += ' ' + timeString
  }
  
  return output
};

export const isValidTimeFormat = (time: string) => {
  const timeRegex = /^(0?[1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)$/i;
  
  const match = time.match(timeRegex);
  
  if (match && match[1] && match[2] && match[3]) {
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    
    if ((hours >= 1 && hours <= 12) && (minutes >= 0 && minutes <= 59)) {
      return true;
    }
  }

  return false;
}