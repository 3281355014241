import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useInput } from "../utils/forms";
import { Toast } from "../utils/notifications";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const Home: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const { value: email, bind: bindEmail } = useInput("");
  const { value: code, bind: bindCode } = useInput("");

  useEffect(() => {
    navigate("/signin", { replace: true });
  });
  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.confirmSignUp(email, code);
      Toast("Success!!", "Verified Successfully", "success");
      navigate("/signin", { replace: true });
    } catch (error: any) {
      Toast("Error!!", error.message, "danger");
    }
    setLoading(false);
  };

  return (
    // <form
    //   style={{
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "space-between",
    //   }}
    //   onSubmit={handleSubmit}
    // >
    //   <h1 style={{ fontSize: "22px", fontWeight: 800 }}>
    //     {" "}
    //     Verify Your Account
    //   </h1>
    //   <Field label="Email" {...bindEmail} type="email" />
    //   <Field label="Verification Code" {...bindCode} />
    //   <Button
    //     variant="contained"
    //     color="primary"
    //     size="large"
    //     type="submit"
    //     disabled={loading}
    //   >
    //     {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
    //     Verify your account
    //   </Button>
    <DLink to="/signin">Go to Login &rarr;</DLink>
    // </form>
  );
};

export default Home;
