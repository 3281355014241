import { Amplify, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Confirmation from "./components/Home";
import Login from "./components/Login/Login";
import { COGNITO } from "./configs/aws";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Protected from "./components/ProtectedRoute";

import ReportConvertToPdf from "./components/ReportConvertToPdf";
import ReportConvertToPdfFromApi from "./components/ReportConvertToPdfFromApi";
import "./fonts/Gill Sans Bold.otf";
import "./fonts/Gill Sans Light.otf";
import "./fonts/Gill Sans Medium.otf";
import "./fonts/Gill Sans.otf";
import "./index.css";

import "react-datepicker/dist/react-datepicker.css";

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    const checkLoggedInUser = async () => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        console.log(user, "This is current user");
        if (user) {
          setLoggedIn(true);
          console.log("Loggedin", loggedIn);
        } else {
          setLoggedIn(false);
        }
      } catch (e) {
        console.log("Failed to load dashboard");
        setLoggedIn(false);
      }
    };
  }, []);

  return (
    <>
      <ReactNotifications />

      <Router>
        <Routes>
          <Route path="/" element={<Confirmation />} />
          <Route path="/signin" element={<Login />} />

          <Route
            path="/dashboard"
            element={
              <Protected loggedIn={loggedIn}>
                <Dashboard />
              </Protected>
            }
          />
          <Route path="/convert-to-pdf" element={<ReportConvertToPdf />} />
          <Route path="/view-report-for-pdf" element={<ReportConvertToPdfFromApi />} />
          <Route path="/reply-from-azure" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
