import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BiovicaLogo from "../../assets/biovica-logo.png";
import { useInput } from "../../utils/forms";
import { Toast } from "../../utils/notifications";
import "./Login.css";

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Login: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const { value: newPassword, bind: bindNewPassword } = useInput("");
  const { value: fullName, bind: bindName } = useInput("");

  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();

    try {
      let user = await Auth.signIn(email, password);

      console.log("Login->user: ", user);
      setCurrentUser(user);
      if (user.challengeName && user.challengeName === "NEW_PASSWORD_REQUIRED") {
        console.log("here it is calling here");
        setIsDialogOpen(true);
      } else {
        Toast("Success!!", "Login Successfully", "success");
        console.log("login success");
        navigate("/dashboard", { replace: true });
      }
    } catch (error: any) {
      Toast("Error!!", error.message, "danger");
    }
    //
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleNewPasswordChallenge = async (e: any) => {
    e.preventDefault();
    try {
      let user = currentUser;
      console.log("user after: ", user);

      user = await Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword, // the new password
        // requiredAttributes,
        {
          name: fullName,
        }
      );

      Toast("Success!!", "Changed Password Successfully", "success");
      setCurrentUser(user);
      navigate("/dashboard", { replace: true });
    } catch (error: any) {
      console.log("error after: ", error);
      Toast("Error!!", error.message, "danger");
    }

    setIsDialogOpen(false);
  };

  return (
    <>
      <form className="login-form" onSubmit={handleSubmit} style={{ height: window.innerHeight }}>
        <div className="login-form-box">
          <div className="biovica-logo">
            <img src={BiovicaLogo} height={50} width={300}></img>
          </div>
          <div className="field-box">
            <Field label="Email" {...bindEmail} type="email" className="field" />
          </div>
          <div className="field-box">
            <Field label="Password" type="password" {...bindPassword} className="field" />
          </div>
          <div className="login-button-box">
            <Button variant="contained" className="login-button" size="large" type="submit" disabled={loading}>
              {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
              Login
            </Button>
          </div>
        </div>

        {/* <DLink to="/signup">make a new account &rarr;</DLink> */}
      </form>

      {isDialogOpen && (
        <div>
          <Dialog
            open={isDialogOpen}
            // TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Force Password Change"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Field label="New Password" type="password" {...bindNewPassword} />
              </DialogContentText>
              <DialogContentText id="alert-dialog-slide-description">
                <Field label="Name" type="text" {...bindName} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNewPasswordChallenge}>Submit</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};
// }
export default Login;
