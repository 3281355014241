import React, { useState } from "react";
import DatePicker from "react-datepicker"
import "./PatientSampleInformation.css";

const PatientSampleInformation = ({
  orderDate,
  setOrderDate,
  collectedDate,
  setCollectedDate,
  collectedTime,
  setCollectedTime,
  patientSampleType,
  setPatientSampleType,
  reportedDate,
  setReportedDate,
  sampleID,
  setSampleID,
  institutionSampleID,
  setInstitutionSampleID,
}: any) => {
  
  return (
    <div className="order-table">
      <div className="patient-sample-information-header">Patient Sample Information</div>
      <div className="filed-value">
        <div className="filed">Ordered:</div>
        <div className="input-value">
          <DatePicker
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/dd/yyyy"
            selected={orderDate}
            onChange={(date) => setOrderDate(date)}
          />
        </div>
        
      </div>
      <div className="under-line"></div>
      <div className="filed-value">
        <div className="filed">Collected:</div>
        <div className="input-value collected">
          <div className="collected-date">
            <span>Date: </span>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/dd/yyyy"
              selected={collectedDate}
              onChange={(date) => setCollectedDate(date)}
            />
          </div>
          <div className="collected-time">
            <span>Time: </span>
            <input
              type="text"
              className="input-time"
              placeholder="hh:mm AM/PM"
              value={collectedTime}
              onChange={(e) => setCollectedTime(e.target.value)}
            />
            {/* <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption="Time"
              dateFormat="h:mm aa"
              selected={collectedTime}
              onChange={(time) => {
                console.log('time: ', time)
                setCollectedTime(time)
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className="under-line"></div>
      <div className="filed-value">
        <div className="filed">Type:</div>
        <select
          value={patientSampleType}
          onChange={(e) => setPatientSampleType(e.target.value)}
          className="input-value"
        >
          <option value="Serum">Serum</option>
          <option value="Blood">Blood</option>
        </select>
      </div>
      <div className="under-line"></div>
      <div className="filed-value">
        <div className="filed">Reported:</div>
        <div className="input-value">
          <DatePicker
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/dd/yyyy"
            selected={reportedDate}
            onChange={(date) => setReportedDate(date)}
          />
        </div>
      </div>
      <div className="under-line"></div>
      <div className="filed-value">
        <div className="filed">Sample ID:</div>
        <input
          type="text"
          className="input-value"
          placeholder="Sample ID"
          value={sampleID}
          onChange={(e) => setSampleID(e.target.value)}
        />
      </div>
      <div className="under-line"></div>
      <div className="filed-value">
        <div className="filed">Institution ID:</div>
        <input
          type="text"
          className="input-value"
          placeholder="Institution ID"
          value={institutionSampleID}
          onChange={(e) => setInstitutionSampleID(e.target.value)}
        />
      </div>
    </div>
  );
};

export default PatientSampleInformation;
