import React, { useEffect, useState } from "react";
import { getFormmatedDate } from "../../utils/helper";
import "./TestResultHistory.css";

const TestResultHistory = ({ datePointsTable }: any) => {
  const [testResultDates, setTestResultDates] = useState<string[]>([]);
  const [testResultValues, setTestResultValues] = useState<number[]>([]);
  const [reversedDatePointsTable, setReversedDatePointsTable] = useState<any[]>([]);

  useEffect(() => {
    setReversedDatePointsTable(datePointsTable.slice().reverse());
  }, [datePointsTable]);

  useEffect(() => {
    const _dates: string[] = [];
    const _values: number[] = [];
    reversedDatePointsTable.forEach((_item) => {
      console.log('typeof point: ', typeof _item.point, _item.point)
      _dates.push(getFormmatedDate(_item.date));
      _values.push(parseFloat(_item.point));
    });
    setTestResultDates(_dates);
    setTestResultValues(_values);
  }, [reversedDatePointsTable]);

  const getFormattedTestValue = (_value: number) => {
    // console.log('typeof point into: ', typeof _value, _value)
    return <span>
      {
        _value
          ? (
            _value > 2000
              ? '>2000'
              : _value > 100
                ? _value : "<100"
            ) : ""
      }
    </span>
  }

  return (
    <div className="test-result-history">
      <div className="test-result-history-header">History of Test Results</div>
      <div className="divtum-unit">DiviTum Activity Units (DuA)</div>
      <div className="test-result-history-data">
        <div className="items-column">
          <div className="each-test">
            <div className="most-recent-value">
              <div className="test-date">{testResultDates[0]}</div>
              <div className="test-value">
                {getFormattedTestValue(testResultValues[0])}
              </div>
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[1]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[1])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[2]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[2])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[3]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[3])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[4]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[4])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[5]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[5])}
            </div>
          </div>
        </div>
        <div className="items-column">
          <div className="each-test">
            <div className="test-date">{testResultDates[6]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[6])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[7]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[7])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[8]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[8])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[9]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[9])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[10]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[10])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[11]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[11])}
            </div>
          </div>
        </div>
        <div className="items-column">
          <div className="each-test">
            <div className="test-date">{testResultDates[12]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[12])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[13]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[13])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[14]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[14])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[15]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[15])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[16]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[16])}
            </div>
          </div>
          <div className="each-test">
            <div className="test-date">{testResultDates[17]}</div>
            <div className="test-value">
              {getFormattedTestValue(testResultValues[17])}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestResultHistory;
