import React, { useState, useEffect, useRef, createRef } from 'react'
import DatePicker from "react-datepicker"

import './custom-chart.css'

interface PropI {
  datePointsTable: {
    point: number
    date: Date
  }[]
  setDatePointsTable: React.Dispatch<React.SetStateAction<{
    point: number;
    date: Date;
}[]>>
}

const CustomChart = ({ datePointsTable, setDatePointsTable, collectedDate }: any) => {
  const [chartDate, setChartDate] = useState<any>(collectedDate || '')
  const [chartPoint, setChartPoint] = useState('')
  const [chartDayCycle, setChartDayCycle] = useState('')
  const [pointRefs, setPointRefs] = useState<any>([])
  const [pointLines, setPointLines] = useState<any>([])
  const [datePointsChart, setDatePointsChart] = useState<any[]>([])//[...datePointsTable]

  const rowHeightRef = createRef<HTMLDivElement>()

  useEffect(() => {
    setChartDate(collectedDate)
  }, [collectedDate])


  useEffect(() => {
    console.log('datePointsTable: ', datePointsTable)
  }, [datePointsTable])

  useEffect(() => {
    console.log('datePointsChart.length: ', datePointsChart.length)
    setPointRefs((prevRefs: any) =>
      Array(datePointsChart.length)
        .fill(0)
        .map((_, i) => prevRefs[i] || createRef())
    )
  }, [datePointsChart])
  
  
  useEffect(() => {
    if (datePointsTable.length <= 8) {
      setDatePointsChart(datePointsTable)
    } else {
      setDatePointsChart(datePointsTable.slice(-8))
    }
    
  }, [datePointsTable])

  useEffect(() => {
    
    newDrawer()
  }, [pointRefs])

  // as from new design, the lines are reomved
  const newDrawer = () => {
    if (pointRefs.length < 2) {
      return
    }
    const tempJSONs = []
    const tempLines = []
    for (let _in = 0; _in < pointRefs.length - 1; ++_in) {
      // if (_in !== 0) break
      console.log('\ndrawer _in: ', _in)
      let elem1 = pointRefs[_in].current
      let elem2 = pointRefs[_in + 1].current

      const rect1 = elem1.getBoundingClientRect()
      const off1 = {
        right: rect1.right + window.pageXOffset,
        top: rect1.top + window.pageYOffset,
        width: rect1.width || elem1.offsetWidth,
        height: rect1.height || elem1.offsetHeight,
      }
      const rect2 = elem2.getBoundingClientRect()
      const off2 = {
        left: rect2.left + window.pageXOffset,
        top: rect2.top + window.pageYOffset,
        width: rect2.width || elem2.offsetWidth,
        height: rect2.height || elem2.offsetHeight,
      }

      // console.log('drawer offset1: ', off1)
      // console.log('drawer offset2.width: ', off2.width)
      // console.log('drawer rect1.left: ', rect1.left)
      // console.log('drawer rect1.left: ', rect1.left)
      console.log('drawer elem1.innerText: ', elem1.innerText.includes('<'))

      const thickness = 1

      var x1 = off1.right - 1// + off1.width / 2.0
      var y1 = off1.top + off1.height / 2.0
      // top right
      var x2 = off2.left + 1// + off2.width / 2.0
      var y2 = off2.top + off2.height / 2.0

      // distance
      var length = Math.sqrt(
        (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1) * 1.0
      ) + 2
      // console.log('length: ', length)
      // angle
      const angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI) * 1.0 + 180

      let cx = 70 //
      const cy = parseFloat(elem1.style.top.slice(0, -2)) + 16
      console.log('drawer cx, cy: ', cx, cy)

      // angle = 0
      if (elem1.innerText.includes('<') || elem1.innerText.includes('>')) {
        cx += 5
      }

      // make hr
      tempJSONs.push({
        length,
        angle,
      })

      var htmlLine = (
        <div
          style={{
            position: 'absolute',
            top: cy + 'px',
            left: cx + 'px',
            padding: '0',
            height: thickness + 'px',
            width: length + 'px',
            transform: 'rotate(' + angle + 'deg)',
            transformOrigin: '0 0',

            backgroundColor: 'rgb(0, 58, 93)',
            lineHeight: '1px',
          }}
        ></div>
      )

      tempLines.push(htmlLine)
      console.log('\n')
    }

    setPointLines(tempLines)
  }

  const getCalculatedTop = (point: any) => {
    let topPx = -50.5 // below 100
    const height = 21.0
    const margin = 42.0
    const largeGap = margin + height
    const smallGap = height

    if (point >= 100) {
      topPx -= 8
    }
    if (point > 100) {
      const result: number = Math.min(
        largeGap,
        ((point - 100) / 100.0) * largeGap
      )
      let high = parseFloat(result.toString()) //.toFixed(2)
      topPx -= high
    }
    if (point > 200) {
      let high = parseFloat(
        Math.min(largeGap, ((point - 200) / 100.0) * largeGap).toString()
      ) //.toFixed(2)
      topPx -= high
    }
    if (point > 300) {
      let high = parseFloat(
        Math.min(smallGap, ((point - 300) / 100.0) * smallGap).toString()
      ) //.toFixed(2)
      topPx -= high
    }
    if (point > 400) {
      let high = parseFloat(
        Math.min(smallGap, ((point - 400) / 100.0) * smallGap).toString()
      ) //.toFixed(2)
      topPx -= high
    }

    if (point > 500) {
      let high = parseFloat(
        Math.min(smallGap, ((point - 500) / 100.0) * smallGap).toString()
      ) //.toFixed(2)
      topPx -= high
    }
    if (point > 600) {
      let high = parseFloat(
        Math.min(smallGap, ((point - 600) / 100.0) * smallGap).toString()
      ) //.toFixed(2)
      topPx -= high
    }
    if (point > 700) {
      let high = parseFloat(
        Math.min(smallGap, ((point - 700) / 100.0) * smallGap).toString()
      ) //.toFixed(2)
      topPx -= high
    }
    if (point > 800) {
      let high = parseFloat(
        Math.min(smallGap, ((point - 800) / 100.0) * smallGap).toString()
      ) //.toFixed(2)
      topPx -= high
    }
    if (point > 900) {
      let high = parseFloat(
        Math.min(smallGap, ((point - 900) / 1100.0) * smallGap).toString()
      ) //.toFixed(2)
      topPx -= high
    }

    if (point > 2000) {
      topPx -= 10
    }

    return topPx + 'px'
  }

  return (
    <div className="custom-chart-container">
      <div className="main-chart">
        <div className="main-chart-row row-item-2000">
          <div className="row-item-left">
            <span>2000</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-900" ref={rowHeightRef}>
          <div className="row-item-left">
            <span>900</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-800">
          <div className="row-item-left">
            <span>800</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-700">
          <div className="row-item-left">
            <span>700</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-600">
          <div className="row-item-left">
            <span>600</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-500">
          <div className="row-item-left">
            <span>500</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-400">
          <div className="row-item-left">
            <span>400</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-300">
          <div className="row-item-left">
            <span>300</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-200">
          <div className="row-item-left">
            <span>200</span>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row row-item-100">
          <div className="row-item-left">
            <span>100</span>
          </div>
          <div className="row-item-right"></div>
        </div>

        <div className="main-chart-row main-chart-row-custom row-item-250">
          <div className="row-item-left">
            <span>250<sup>*</sup></span>
            <div className="left-label">
              <span>Endocrine</span>
              <span>therapy cutoff</span>
            </div>
          </div>
          <div className="row-item-right"></div>
        </div>
        <div className="main-chart-row main-chart-row-custom row-item-145">
          <div className="row-item-left">
            <span>145</span>
            <div className="left-label">
              <span>CDK4/6</span>
              <span>inhibitor cutoff</span>
            </div>
          </div>
          <div className="row-item-right"></div>
        </div>

        <div className="y-legend">
          <span>DuA</span>
        </div>

        <div className="left-bar"></div>
        <div className="bottom-bar"></div>
      </div>
      <div className="datepoint-container">
        <div className="date-legend"><span><strong>DATE:</strong></span></div>
        {datePointsChart.map((_datePoint, _in) => (
          <div
            className={`datepoint-item datepoint-item-loop datepoint-item-${
              _in + 1
            }`}
            key={_in}
          >
            <DatePicker
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/dd/yyyy"
              selected={_datePoint.date}
              onChange={(date) => {
                setDatePointsTable((_prev: any) =>
                  _prev.map((_prevItem: any, _prevIndex: number) => {
                    if (_prevIndex === _in) {
                      return {
                        ..._prevItem,
                        date
                      }
                    }
                    return _prevItem
                  })
                )
              }}
            />
            <input
              type="text"
              className="input-daycycle"
              placeholder='Day Cycle'
              value={_datePoint.dayCycle}
              onChange={(event) => {
                setDatePointsTable((_prev: any) =>
                  _prev.map((_prevItem: any, _prevIndex: number) => {
                    if (_prevIndex === _in) {
                      return {
                        ..._prevItem,
                        dayCycle: event.target.value,
                      }
                    }
                    return _prevItem
                  })
                )
              }}
            />
            
            <input
              type="number"
              min={0}
              className="input-point"
              placeholder='Point'
              value={_datePoint.point || 0}
              onChange={(event) => {
                if (parseFloat(event.target.value) >= 0 || event.target.value === '') {
                  setDatePointsTable((_prev: any) =>
                    _prev.map((_prevItem: any, _prevIndex: number) => {
                      if (_prevIndex === _in) {
                        return {
                          ..._prevItem,
                          point: event.target.value || 0,
                        }
                      }
                      return _prevItem
                    })
                  )
                }
              }}
            />
            <div
              className={`point point-${_in}`}
              ref={pointRefs[_in]}
              style={{
                top: getCalculatedTop(_datePoint.point),
                backgroundColor:
                  _in === datePointsChart.length - 1 ? '#FEFBD1' : '#FFFFFF',
              }}
            >
              <span>{_datePoint.point < 100 ? '<100' : _datePoint.point > 2000 ? '>2000' : _datePoint.point}</span>
            </div>
            {pointLines[_in] || null}
            <div style={{ height: '21px' }}></div>
            {_in === datePointsChart.length - 1 && (
              <div className="last-box"></div>
            )}
          </div>
        ))}

        <div className="datepoint-item">
          <DatePicker
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/dd/yyyy"
            selected={chartDate}
            onChange={(date) => setChartDate(date)}
          />
          <input
            type="text"
            className="input-daycicle"
            placeholder='Day Cycle'
            value={chartDayCycle}
            onChange={(event: any) => {
              setChartDayCycle(event.target.value)
            }}
          />
          <input
            type="number"
            min={0}
            className="input-point"
            placeholder='Point'
            value={chartPoint}
            onChange={(event: any) => {
              console.log('value: ', event.target.value.length)
              if (parseFloat(event.target.value) >= 0 || event.target.value === '') {
                setChartPoint(parseFloat(event.target.value).toString() || '0')
              } 
            }}
          />
          <button
            disabled={parseFloat(chartPoint) === 0 || isNaN(parseFloat(chartPoint))|| !chartDate}
            onClick={() => {
              setDatePointsTable((_prev: any) => [
                ..._prev,
                {
                  point: chartPoint,
                  date: chartDate,
                  dayCycle: chartDayCycle
                },
              ])
              setChartPoint('0')
              setChartDayCycle('')
              setChartDate(collectedDate)
            }}
          >
            Enter
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomChart
