import React from "react";
import "./Common.css";

const VerticalDotLine = (props: any) => {
  var items = [];
  for (var i = 1; i < props.height; i++) {
    items[i] = i;
  }
  return (
    <div className="vertical">
      {items.map((item) => (
        <div className="dot">.</div>
      ))}
    </div>
  );
};

export default VerticalDotLine;
